<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
   
  }
}
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
